.cardContainer {
  width: 31%;
  margin-bottom: 25px;
  margin-left: 15px;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .cardContainer {
    width: 94%;
    margin-left: 0;
  }
}
