.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: gray;
  width: 50%;
  padding-bottom: 10px;
  color: gray;
}

.body {
  padding-left: 12px;
  padding-right: 12px;
  line-height: 20px;
}
