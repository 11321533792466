.container {
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: linear-gradient(
    90deg,
    rgba(36, 0, 29, 0.742734593837535) 16%,
    rgba(128, 10, 14, 0.8687850140056023) 54%,
    rgba(255, 132, 0, 1) 100%
  );
  cursor: pointer;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.godText {
  color: orange;
}

.aboutContainer {
  padding-top: 6rem;
  align-items: "center";
  display: "flex";
  flex-direction: "column";
}
.name {
  margin-bottom: 0;
  margin-top: 7px;
}

.subtitle {
  margin: 0;
  padding-top: 7px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  padding-top: 15px;
  justify-content: center;
}
