.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-around; */
  margin-left: 2%;
  margin-right: 2%;
  padding-bottom: 30px;
}

.titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: gray;
  width: 50%;
  padding-bottom: 10px;
  color: gray;
}

.body {
  padding-left: 12px;
  padding-right: 12px;
  line-height: 20px;
}

.cardContainer {
  width: 31%;
  margin-bottom: 25px;
  margin-left: 15px;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .cardContainer {
    width: 94%;
    margin-left: 0;
  }
}
