.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-around; */
  margin-left: 2%;
  margin-right: 2%;
  padding-bottom: 30px;
}

.titleContainer {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: gray;
  width: 50%;
  padding-bottom: 10px;
  color: gray;
}
